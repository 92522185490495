import React from 'react'
import {AdminLayout, CreateEditProvider, ViewProviders} from 'widgets'
import {SEO, Table, Button, Modal} from 'components'
import { quitLoading, setLoading, showError, confirm, showSuccess} from 'utils'
import { ProviderService } from 'services/modules/ProviderService'
import {Provider} from 'models'

export default () => {
    const [providers, setProviders] = React.useState<Provider[]>([])
    const [visible, setVisible] = React.useState<boolean>(false)
    const [view, setView] = React.useState<boolean>(false)
    const [element, setElement] = React.useState<Provider | null>(null)
    const load = async () => {
        setLoading()
        try {
            const _providers = await ProviderService.get()
            setProviders(_providers)
        } catch (error) {
            console.log('>>: error > ', error)
            showError(error?.response?.data?.msg)
        }finally{
            quitLoading()
        }
    }
    React.useEffect(() => {
        load()
    }, [])
    const viewUser = (_element: Provider) => {
        setElement(_element)
        setView(true)
    }
    const editUser = (_element: Provider) => {
        setElement(_element)
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
        setView(false)
        setElement(null)
        load()
    }
    const deleteUser = (_element: Provider) => {
        confirm('Esta seguro desea eliminar el proveedor: '+_element.name, async () => {
            setLoading()
            try {
                const {msg} = await ProviderService.delete(_element)
                showSuccess(msg)
                load()
            } catch (error) {
                showError(error?.response?.data?.msg)
            }finally{
                quitLoading()
            }
        })
    }
    return(
        <AdminLayout>
            <SEO
                title="Proveedores"
            />
            <Modal
                visible={visible}
                onClose={onClose}
            >
                <CreateEditProvider
                    element={element}
                    onClose={onClose}
                />
            </Modal>
            <Modal
                visible={view}
                onClose={onClose}
            >
                <ViewProviders
                    element={element}
                    onClose={onClose}
                />
            </Modal>
            <div className="providers">
                <div className="container">
                    <Table
                        header={
                            ['#', 'Nombre', 'Telefono', 'Email', 'Acciones']
                        }
                        data={providers.length}
                        title="Proveedores"
                        right={
                            <Button
                                icon="plus"
                                className="primary"
                                small
                                onClick={() => setVisible(true)}
                            />
                        }
                    >
                        {
                            providers?.map((element, i) => {
                                return(
                                    <tr key={ i }>
                                        <th scope="row">{ element.id }</th>
                                        <td> {element.name} </td>
                                        <td> {element.phone} </td>
                                        <td> {element.email} </td>
                                        <td>
                                            <Button
                                                icon="eye"
                                                onClick={() => viewUser(element)}
                                                small
                                            />
                                            <Button
                                                icon="edit"
                                                className="info text-white"
                                                onClick={() => editUser(element)}
                                                small
                                            />
                                            <Button
                                                icon="trash"
                                                className="danger text-white"
                                                onClick={() => deleteUser(element)}
                                                small
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </Table>
                </div>
            </div>
        </AdminLayout>
    )
}